import { useEffect, useState } from 'react';
// import { BrowserView, MobileView } from 'react-device-detect';

import Viewer from '../components/Viewer';

function ScanView() {
    const [scan, setScan] = useState(null);

    const loadScan = () => {
        const parts = window.location.href.split('#');
        const index = parts[parts.length - 1];
        console.log("loading scan, index: ", index);
        fetch(`https://api.manometric.cloud/scans/v0/scan/${index}`)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    return Promise.reject('scan not found!')
                } else {
                    return Promise.reject('unexpected error: ' + response.status)
                }
            })
            .then(data => {
                console.log(`scan with index ${index} found`);
                const scan = {
                    name: data.form_first_name,
                    path: data.location
                }
                setScan(scan);
            })
            .catch(error => console.log('error: ', error));
    }

    useEffect(() => {
        window.addEventListener("hashchange", (e) => {
            console.log('hash has changed');
            window.location.reload();
        });
        if (!scan) {
            loadScan()
        }
    });
    
    return <div><div className="flex flex-col w-screen h-screen bg-gray-100 font-['europa']">
        <div className="flex flex-row p-3"> 
            <div className="basis-1/5 pl-2">
                {/* <a href="https://manometric.nl/?lang=en">
                    <img src="logo.png" width="45px" alt="logo" />                
                </a> */}
            </div>
            <div className="basis-3/5">
                {(scan && scan.name) &&
                    <div className="text-xl font-medium text-black">{scan.name}'s hand</div>
                }
                {!(scan && scan.name) &&
                    <div className="text-xl font-medium text-black">Your hand</div>
                }
                <p className="text-gray-500 text-sm">Scanned by <a href="https://manometric.nl/?lang=en" className='text-[#36796B]'>Manometric</a> in 0.01s</p>
            </div>
        </div>
        <div className="h-full">
            <Viewer scan={scan} />
            {/* <div className="absolute bottom-60 w-full block z-50">
                <BrowserView>
                    <p className="text-slate-500">Use mouse to pan, rotate &amp; zoom</p>
                </BrowserView>
                <MobileView>
                    <p className="text-slate-500">Swipe to pan &amp; rotate, pinch to zoom</p>
                </MobileView>
            </div> */}
        </div>
        <div className="p-3 pt-6 h-48">
            <div>
                <p className="text-xl font-normal">
                    The new standard in <br />
                    orthopedic care.
                </p>
            </div>
            <div className="pt-6 pb-6">
                <a href="https://manometric.nl/?lang=en">
                    <img src="logo-black-block.png" className='inline' width="90px" alt="logo" />                
                </a>
            </div>
        </div></div>
        <div className="flex flex-col p-3 pt-6 bg-gray-300">
            <div>
                <p className="text-md font-normal">
                    Follow our journey
                </p>
            </div>
            <div className='flex flex-row gap-4 justify-center pt-4 pb-4'>
                <a href="https://www.linkedin.com/company/manometric">
                    <img src="linkedin.png" className='inline' width="40px" alt="linkedin" />                
                </a>
                <a href="https://www.instagram.com/manometric_nl">
                    <img src="instagram.png" className='inline' width="40px" alt="instagram" />                
                </a>
            </div>
        </div>

    </div>
}

export default ScanView